import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header/header"
import "./layout.scss"
import "../../styles/globals.scss"

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  return (
    <>
      <div className="Layout">
        <Header />
        <main className="Layout__children">{children}</main>
        <footer className="Layout__footer">
          <a
            href="https://akshatbisht.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="Layout__footer__link"
          >
            Developed by Akshat Bisht
          </a>
        </footer>
      </div>
    </>
  )
}
