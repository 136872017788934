import { Link } from "gatsby"
import React from "react"

import "./header.scss"

const Header = () => (
  <header className="Header">
    <p
      // href="/"
      // target="_blank"
      // rel="noopener noreferrer"
      className="Header__link secondary disabled"
    >
      Code
    </p>
    <Link to="/" className="Header__gatsby-link">
      <h1 className="Header__link main">KARDOMETER</h1>
    </Link>
    <Link to="/about" className="Header__gatsby-link">
      <h1 className="Header__link secondary">About</h1>
    </Link>
  </header>
)

export default Header
